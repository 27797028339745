.first_divs {
  background-color: greenyellow !important;
  width: 100px !important;
  height: 100px !important;
  background: red;
}

.get_Help {
  background-color: red;

}